<template>
  <div class="content">
    <!-- 头部 -->
    <div class="header">
      <img class="banner" src="../assets/index/banner3.png" alt="" />
      <!-- <div class="logoTitle">
        <div class="logo"><img src="../assets/index/logo.png" alt=""></div>
      </div> -->
      <div class="intrduce">
        <div class="top">字景数科</div>
        <div class="bottom">市场的需求就是我们创新的动力</div>
      </div>
      <div class="system">
        <div class="block">
          <div class="name">地图驾驶舱</div>
          <div class="English">Map cockpit</div>
        </div>
        <div class="line"></div>
        <div class="block">
          <div class="name">后台管理系统</div>
          <div class="English">Background managemen</div>
        </div>
      </div>
    </div>
    <!-- 公司简介 -->
    <div class="companyIntrodution">
      <div class="introduce">
        <div class="title">字景数科</div>
        <div class="line"></div>
        <div class="intro">公司介绍</div>
        <div class="block">
          杭州字景数科电子有限公司位于杭州市未来科技城浙江数智引擎创新园内，紧邻西溪湿地，办公环境优雅，全面享受余杭产业政策。公司专业从事智能输配电产品的研发、生产、销售和服务，业务重点在三大领域：一、智能输配电及控制设备；二、智能仪器仪表；三、电子元器件。
          公司的核心是技术创新与服务，研发人员占比80%以上。大专、本科、硕士研究生梯级合理配置，充分发挥个人的想象力和创造力，保证所有产品均百分之百自主研发。
          公司秉承“市场的需求就是我们创新的动力”企业文化，为输配电行业安全、便捷、节能贡献自己的一份力量。
        </div>
        <div class="btngroup">
          <div class="btn" @click="getMore()">了解更多</div>
        </div>
      </div>
      <img class="img" src="../assets/index/gs.png" alt="" />
    </div>
    <!-- 热门产品 -->
    <div class="main">
      <div class="title">热门产品</div>
      <div class="line"></div>
      <div class="flex">
        <div class="english">Popular products</div>
        <div class="more" style="margin-top: 0.3rem">
          <div class="font" @click="viewMore('产品列表')">
            <span>查看更多 </span>
            <img src="../assets/index/arrow.png" alt="" />
          </div>
          <div class="textline"></div>
        </div>
      </div>
    </div>
    <div class="product">
      <div
        class="block"
        v-for="(item, i) in productList.slice(0, 8)"
        :key="i"
        @click="viewDetail(item, '产品详情',i)"
      >
        <div class="name">{{ item.productName }}</div>
        <div class="type">{{ item.productAlias }}</div>
        <div class="intro">{{ item.productDescription }}</div>
        <div class="flex">
          <div class="more">
            <span style="vertical-align: middle">查看更多 ></span>
            <!-- <img style="vertical-align: middle;margin-left: 2px;margin-top: 1px;" src="../assets/index/jt5.png" alt=""> -->
          </div>
          <img class="img" :src="item.productImage" alt="" />
        </div>
      </div>
    </div>
    <!-- 客户项目 -->
    <div class="main">
      <div class="title">客户项目</div>
      <div class="line"></div>
      <div class="flex">
        <div class="english">Customer project</div>
        <div class="more" @click="viewMore('客户项目展示')">
          <div class="font">
            <span>查看更多 </span>
            <img src="../assets/index/arrow.png" alt="" />
          </div>
          <div class="textline"></div>
        </div>
      </div>
    </div>
    <div class="project">
      <div
        class="block"
        v-for="(item, i) in projectList"
        :key="i"
        @click="viewDetail(item, '客户案例详情',i)"
      >
        <img
          class="img"
          style="height: 2.5rem"
          :src="item.projectImage"
          alt=""
        />
        <div class="box">
          <div class="title">{{ item.projectName }}</div>
          <div class="intro">
            {{ item.projectDescription }}
          </div>
          <div class="btn">阅读他们的案例 ></div>
        </div>
      </div>
    </div>
    <!-- 公司新闻 -->
    <div class="main">
      <div class="title">公司新闻</div>
      <div class="line"></div>
      <div class="flex">
        <div class="english">Company News</div>
        <div class="more" @click="viewMore('公司新闻')">
          <div class="font">
            <span>查看更多 </span>
            <img src="../assets/index/arrow.png" alt="" />
          </div>
          <div class="textline"></div>
        </div>
      </div>
    </div>
    <div class="news">
      <div class="img">
        <img class="big" src="../assets/index/t13.png" alt="" />
        <!-- <img
          @click="isPlay = true"
          class="small"
          src="../assets/index/bf.png"
          alt=""
        /> -->
      </div>
      <div class="block">
        <div
          class="flex"
          v-for="(item, i) in newsList"
          :key="i"
          @mouseover="hover(i)"
          @mouseleave="hoverLeave()"
        >
          <div class="left">
            <div class="day">{{ cropStr(item.gmtCreate, 5, 10) }}</div>
            <div class="year">{{ cropStr(item.gmtCreate, 0, 4) }}</div>
          </div>
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="content">{{ item.intro }}</div>
            <div
              class="lookDetail"
              @click="viewDetail(item, '新闻内容详情',i)"
            >
              <span class="font" style="vertical-align: middle">查看详情</span>
              <img
                v-show="!item.hover"
                style="
                  vertical-align: middle;
                  margin-top: 2px;
                  margin-left: 5px;
                "
                src="../assets/index/jt2.png"
                alt=""
              />
              <img
                v-show="item.hover"
                style="
                  vertical-align: middle;
                  margin-top: 2px;
                  margin-left: 5px;
                "
                src="../assets/index/jt1.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <video-player
      :isShow="isPlay"
      @closeHandler="isPlay = false"
    ></video-player>
  </div>
</template>
<script>
// 在页面加载完成后执行
window.onload = function () {
  // 将滚动条置于顶部
  setTimeout(function () {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 100);
};
import t1 from "../assets/index/t1.png";
import t2 from "../assets/index/t2.png";
import t3 from "../assets/index/t3.png";
import t4 from "../assets/index/t4.png";
import t5 from "../assets/index/t5.png";
import t6 from "../assets/index/t6.png";
import t7 from "../assets/index/t7.png";
import t8 from "../assets/index/t8.png";
import t9 from "../assets/index/t9.png";
import t10 from "../assets/index/t10.png";
import t11 from "../assets/index/t11.png";
import VideoPlayer from "./TechnicalSupport/VideoPlayer.vue";
export default {
  components: { VideoPlayer },
  data() {
    return {
      isPlay: false,
      // 热门产品
      listLength: 8,
      // 产品请求参数
      productParam: {
        pageNum: 1,
        pageSize: 10,
        // 产品状态0：待上架 1：上架
        status: 1,
      },
      //  客户案例请求参数
      customParam: {
        pageNum: 1,
        pageSize: 10,
      },
      // 新闻请求参数
      newsParam: {
        pageNum: 1,
        pageSize: 3,
      },
      productList: [
        {
          name: "电源采集模块",
          type: "BXDAS-D",
          intro: "RS485总线通讯接口,Modbus R",
          img: t1,
        },
        {
          name: "智能一体化控制柜",
          type: "BXDAS",
          intro: "1、根据用户需求定制; 2、独立终端控制…",
          img: t2,
        },
        {
          name: "电源采集控制模块",
          type: "BXDAS-D",
          intro: "RS485总线通讯接口,Modbus RTU协议RS485…",
          img: t3,
        },
        {
          name: "电源采集控制模块",
          type: "BXDAS-D",
          intro: "1、RS485总线通讯接口,Modbus RTU协议…",
          img: t4,
        },
        {
          name: "元机触屏",
          type: "BXDAS-PT",
          intro: "对一体化箱(柜)内的数据进行计算和转换，将得…",
          img: t5,
        },
        {
          name: "照明控制模块",
          type: "BXDAS-ZM",
          intro: "1、RS485总线通讯接口,Modbus RTU协议…",
          img: t6,
        },
        {
          name: "漏电控制模块",
          type: "BXDAS-CZ",
          intro: "1、RS485总线通讯接口,Modbus RTU协议…",
          img: t7,
        },
        {
          name: "电动机控制模块",
          type: "BXDAS-M",
          intro: "1、RS485总线通讯接口,Modbus RTU协议…",
          img: t8,
        },
        {
          name: "照明控制模块",
          type: "BXDAS-ZM",
          intro: "1、RS485总线通讯接口,Modbus RTU协议…",
          img: t6,
        },
        {
          name: "漏电控制模块",
          type: "BXDAS-CZ",
          intro: "1、RS485总线通讯接口,Modbus RTU协议…",
          img: t7,
        },
        {
          name: "电动机控制模块",
          type: "BXDAS-M",
          intro: "1、RS485总线通讯接口,Modbus RTU协议…",
          img: t8,
        },
      ],
      // 客户项目
      projectList: [
        {
          img: t9,
          name: "合作公司名称及其项目名称合作公司",
          intro:
            "项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容…",
        },
        {
          img: t10,
          name: "合作公司名称及其项目名称合作公司",
          intro:
            "项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容…",
        },
        {
          img: t11,
          name: "合作公司名称及其项目名称合作公司",
          intro:
            "项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容…",
        },
      ],
      // 公司新闻
      newsList: [
        {
          hover: false,
          day: "01-22",
          year: "2023",
          title: "董事长寄语标题",
          content:
            "董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话…",
        },
        {
          hover: false,
          day: "01-23",
          year: "2023",
          title: "新闻名称新闻名称",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容",
        },
        {
          hover: false,
          day: "01-22",
          year: "2023",
          title: "董事长寄语标题",
          content:
            "董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话董事长寄语董事长说的话董事长说的话…",
        },
      ],
      // 新闻是否hover
      newsHover: false,
    };
  },
  created() {
    this.requestProductList();
    this.requestCustomList();
    this.requestNewsList();
  },
  computed: {
    arrayWithIndex() {
      return this.projectList.map((item, index) => {
        return { index, item };
      });
    },
  },
  methods: {
    // 截取字符串
    cropStr(str, start, end) {
        return String(str).slice(start, end);
    },
    // 请求产品列表
    requestProductList() {
      var param = {
        pageNum: this.productParam.pageNum,
        pageSize: this.productParam.pageSize,
      };
      this.$post("/web/product/pageList", param)
        .then((res) => {
          // 主要参数
          if (res.code == 1) {
            // console.log(res.data);
            this.productList = res.data.list;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 请求客户案例列表
    requestCustomList() {
      let param = {
        pageNum: this.customParam.pageNum,
        pageSize: this.customParam.pageSize,
      };
      this.$post("/web/cooperationCase/pageList", param).then((res) => {
        // 主要参数
        if (res.code == 1) {
          // console.log(res.data);
          this.$nextTick(() => {
            this.projectList = res.data.list.slice(0, 3);
          });
        } else {
          console.log("异常");
        }
      });
    },
    // 请求新闻列表
    requestNewsList() {
      let param = {
        pageNum: this.newsParam.pageNum,
        pageSize: this.newsParam.pageSize,
      };
      this.$post("/web/new/pageList", param).then((res) => {
        // 主要参数
        if (res.code == 1) {
          // console.log(res.data);
          this.$nextTick(() => {
            this.newsList = res.data.list.slice(0, 3);
          });
        } else {
          console.log("异常");
        }
      });
    },
    // 了解更多(公司主要内容)
    getMore() {
      this.$router.push("/contact");
      this.$store.commit("changeTheme", true);
      this.$store.commit("changeIndex", 5);
    },
    // 鼠标移动至某一新闻上
    hover(i) {
      this.newsList.forEach((item) => {
        item.hover = false;
        this.newsList[i].hover = true;
      });
    },
    // 鼠标从某一新闻上移走
    hoverLeave() {
      this.newsList.forEach((item) => {
        item.hover = false;
      });
    },
    // 查看更多（详情）
    viewDetail(item, name, i) {
      this.$store.commit("changeTheme", false);
      this.$router.push({
        name: name,
        query: {
          item,
        },
      });
      if (name == "产品详情") {
        localStorage.setItem("productDetail", JSON.stringify(item));
      } else if (name == "客户案例详情") {
        localStorage.setItem("customDetail", JSON.stringify(item));
      } else if (name == "新闻内容详情") {
        localStorage.setItem("newsDetail", JSON.stringify(item));
        localStorage.setItem("index", i);
      }
    },
    // 查看更多（列表）
    viewMore(name) {
      if (name === "公司新闻") {
        this.$store.commit("changeIndex", 4);
      } else if (name == "客户项目展示") {
        this.$store.commit("changeIndex", 3);
      }
      this.$router.push({
        name: name,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 头部
.header {
  width: 100%;
  color: #fff;
  position: relative;
  box-sizing: border-box;
  .banner {
    width: 100%;
    height: auto;
  }
  .logoTitle {
    position: absolute;
    top: 20px;
    left: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .logo {
      color: #5dfff1;
      font-size: 2.5vw;
    }
    .title {
      font-size: 2.03vw;
    }
  }
  .intrduce {
    width: 600px;
    position: absolute;
    top: 30%;
    left: 100px;
    cursor: pointer;
    .top {
      font-size: 5.1vw;
      font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
      font-weight: normal;
      color: #ffffff;
      letter-spacing: 3px;
      margin-bottom: 5px;
    }

    .bottom {
      font-size: 2.03vw;
    }
  }

  .system {
    width: 80%;
    position: absolute;
    bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 10%;

    .line {
      width: 0.16vw;
      height: 50px;
      background: #ffffff;
      border-radius: 1px;
    }

    .block {
      width: 45%;
      text-align: center;
      font-size: 2.03vw;
      cursor: pointer;

      .English {
        color: #b3b3b3;
        font-size: 1.56vw;
      }
    }
  }
}

// 公司简介
.companyIntrodution {
  width: 80%;
  margin: 20px 10%;
  background-color: #f3f3f3;
  // height: 600px;
  display: flex;
  justify-content: space-between;

  .introduce {
    padding: 68px 120px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;

    .title {
      font-size: 1.98vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #272727;
      margin: 5px 0 10px 0;
    }

    .line {
      width: 2.5vw;
      height: 3px;
      background: #127bf2;
      border-radius: 2px;
    }

    .intro {
      font-size: 1.35vw;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #909090;
      margin: 10px 0;
    }

    .block {
      font-size: 0.83vw;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #909090;
      line-height: 25px;
    }

    .btn {
      width: 9.5vw;
      height: 42px;
      background: #127bf2;
      border-radius: 26px;
      margin-top: 30px;
      font-size: 1.03vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
      line-height: 42px;
    }
  }

  img {
    width: 50%;
    margin-right: -10%;
  }
}
// 每个模块的标题部分
.main {
  width: 80%;
  margin: 40px 10% 20px;
  .title {
    font-size: 1.67vw;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #272727;
    cursor: pointer;
  }

  .line {
    width: 2.5vw;
    height: 3px;
    background: #127bf2;
    border-radius: 2px;
    margin: 5px 0;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;

    .english {
      font-size: 1.36vw;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #909090;
      cursor: pointer;
    }

    .more {
      .font {
        font-size: 1vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #127bf2;
        cursor: pointer;

        span {
          vertical-align: middle;
        }
        img {
          vertical-align: middle;
        }
      }

      .textline {
        width: 5vw;
        height: 2px;
        background: #b7b7b7;
        margin-top: 5px;
      }
    }
  }
}

// 热门产品
.product {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 20px 10%;

  .block {
    width: 25%;
    padding: 20px 2.29vw;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #e7e7e7;
    cursor: pointer;

    .name {
      font-size: 1.15vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #565656;
    }

    .type {
      font-size: 1.04vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #565656;
      margin: 5px 0;
    }

    .intro {
      font-size: 0.73vw;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #aeaeae;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 5px 0;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      .more {
        font-size: 0.94vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #565656;
        margin: 10px 0;
        width: 45%;
        white-space: nowrap;
      }

      .img {
        width: 50%;
        height: 1.2rem;
        object-fit: contain;
      }
    }
  }

  .block:hover {
    background: #127bf2;

    .name,
    .type,
    .more {
      color: #fff;
    }

    .intro {
      color: #f5f5f5;
    }
  }
}

// 客户项目
.project {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 20px 10%;

  .block {
    width: 32%;
    box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
    border: 1px solid #e7e7e7;

    img {
      width: 100%;
    }

    .box {
      text-align: center;
      padding: 15px 10px 10px;
      cursor: pointer;
      margin-top: -5px;

      .title {
        font-size: 1.2vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #565656;
      }

      .intro {
        text-align: left;
        font-size: 0.83vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #bbbbbb;
        margin: 15px 0;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 3; //行数
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .btn {
        width: 80%;
        margin: 10px 10%;
        height: 42px;
        background: #127bf2;
        border-radius: 26px;
        color: #fff;
        line-height: 42px;
        font-size: 1.04vw;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
      }
    }

    .box:hover {
      background: #127bf2;

      .title {
        color: #ffffff;
      }

      .intro {
        color: #e7e7e7;
      }
      .btn {
        background: #3692fc;
      }
    }
  }
}

// 公司新闻
.news {
  width: 80%;
  margin: 20px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .img {
    position: relative;
    width: 50%;
    .big {
      width: 90%;
      height: 350px;
    }
    .small {
      width: 70px;
      height: 70px;
      position: absolute;
      top: calc(50% - 35px);
      left: calc(45% - 35px);
    }
  }
  .block {
    width: 50%;
    .flex {
      border-bottom: solid 2px #cccccc;
      padding: 20px 0;
      box-sizing: border-box;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      .left {
        border-right: solid 1px #d8d8d8;
        padding-right: 10px;
        box-sizing: border-box;
        .day {
          white-space: nowrap;
          font-size: 1.98vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #272727;
          cursor: pointer;
        }
        .year {
          text-align: right;
          font-size: 0.94vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #272727;
          cursor: pointer;
        }
      }
      .right {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        .title {
          font-size: 0.94vw;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #3d3d3d;
          cursor: pointer;
        }
        .content {
          font-size: 0.83vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #a0a0a0;
          margin: 5px 0;
          cursor: pointer;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 2; //行数
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .lookDetail {
          text-align: right;
          cursor: pointer;
          span {
            font-size: 0.73vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3d3d3d;
          }
          img {
            height: 0.73vw;
          }
        }
      }
    }
    .flex:hover {
      .title {
        color: #0262dc;
        font-weight: 500;
      }
      .lookDetail span {
        color: #0262dc;
      }
    }
  }
}
</style>
